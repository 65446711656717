<!--20240530_1 新增听课通知功能-->
<template>
  <div class="edu-frame">
    <edu-nav-bar title="停课通知" :show="show" @showPopup="show=true" @hidePopup="show=false"></edu-nav-bar>
    <div class="content-frame">
      <van-cell-group inset style="margin-top: 10px;">
        <van-field
            v-model="wxMsgMaster.className"
            is-link
            readonly
            name="picker"
            label="选择班级"
            placeholder="点击选择班级"
            @click="showClassSetups = true"
        />
        <van-field
            v-model="wxMsgMaster.caption"
            rows="2"
            autosize
            label="停课原因"
            type="textarea"
            maxlength="50"
            placeholder="请输入停课原因"
            show-word-limit
        />
      </van-cell-group>

      <div v-if="wxMsgMaster.wxMsgMasterId != null" style="width: 100%;display: flex;justify-content: center;margin-top: 20px;">
        <van-button square type="primary" :loading="submitting" @click="toSendMsgHistory">查看发送结果</van-button>
      </div>


    </div>
    <div class="bottom-frame">
      <van-button v-if="wxMsgMaster.wxMsgMasterId != null" square block type="danger" :loading="submitting" @click="sendToStudent()">发送消息</van-button>
      <van-button v-if="wxMsgMaster.wxMsgMasterId != null" square block type="warning" style="background-color: orange;border-color: orange;" :loading="submitting" @click="sendToMe()">发送给自己</van-button>
      <van-button square block type="primary" :loading="submitting" @click="saveWxMsgMaster">保存消息</van-button>
    </div>

    <van-popup v-model="showClassSetups">
      <div style="width: 80vw;height: 80vh;">
        <van-nav-bar title="选择班级" />
        <van-search v-model="condition" placeholder="请输入班级名称搜索"></van-search>
        <div style="height: calc(100% - 44px - 46px - 54px);overflow: auto;">
          <template v-for="(cs, index) in classsSetups">

            <van-cell v-if="cs.className.indexOf(condition) > -1" :key="index" is-link @click="selectItem(cs)">
              <template slot="title" style="width: 100%;">
                <div>{{cs.className}}</div>
              </template>
            </van-cell>

          </template>
        </div>
        <div>
          <van-button square block type="primary" style="background-color: #1989fa;border-color: #1989fa;" :loading="submitting" @click="showClassSetups=false">返回</van-button>
        </div>
      </div>
    </van-popup>
  </div>
</template>
<script>
import EduNavBar from "@/components/EduNavBar.vue";
import {Button, Field, CellGroup, Popup, NavBar, Search, Cell} from "vant";
import BaomingApi from "@/api/BaomingApi";
import Tools from "@/api/Tools";
export default {
  components: {
    EduNavBar,
    VanButton: Button,
    VanField: Field,
    VanCellGroup: CellGroup,
    VanPopup: Popup,
    VanNavBar: NavBar,
    VanSearch: Search,
    VanCell: Cell
  },
  data() {
    return {
      show: false,
      submitting: false,
      wxMsgMaster: { caption: '因老师原因，本班本周停课一次，课时顺延。' },
      showClassSetups: false,
      condition: '',
      termId: null,
      classsSetups: null
    }
  },
  methods: {
    selectItem(cs) {
      console.log(cs)
      this.$set(this.wxMsgMaster, 'className', cs.className)
      this.$set(this.wxMsgMaster, 'classId', cs.classId)
      this.showClassSetups = false
    },
    getJiaoXueTermSetup() {
      BaomingApi.getJiaoXueTermSetup({ fdataType: 1 }).then(response => {
        this.termId = response.res.termId
        this.getClassSetups()
      })
    },
    getClassSetups() {
      BaomingApi.getClassSetups({ termId: this.termId }).then(response => {
        this.classsSetups = response.res
      })
    },
    saveWxMsgMaster(){
      this.wxMsgMaster.userId = Tools.getCurCust().custId
      BaomingApi.submitTktz(this.wxMsgMaster).then(response => {
          this.$dialog.alert({message: response.msg})
        if (response.code == 100) {
          this.wxMsgMaster.wxMsgMasterId = response.res.wxMsgMasterId
        }
      })
    },
    sendToMe(){
      BaomingApi.sendTktzMsg({ userId: Tools.getCurCust().custId, wxMsgMasterId: this.wxMsgMaster.wxMsgMasterId }).then(response => {
        this.$dialog.alert({message: response.msg})
      })
    },
    sendToStudent(){
      if (this.submitting) {
        return
      }
      this.$dialog.confirm({title: '提示', message: "请确定是否发送消息？"}).then(() => {
        this.submitting = true
        BaomingApi.sendTktzMsg({ wxMsgMasterId: this.wxMsgMaster.wxMsgMasterId }).then(response => {
          this.submitting = false
          this.$dialog.alert({message: response.msg})
        }).catch(() => { this.submitting = false })
      }).catch(() => { this.submitting = false })


    },
    getWxMsgMasterById(){
      var wxMsgMasterId = this.$route.query.wxMsgMasterId
      BaomingApi.getTktzById({ wxMsgMasterId: wxMsgMasterId }).then(response => {
        if (response.code == 100) {
          this.wxMsgMaster = response.res
        }
      })
    },
    toSendMsgHistory() {
      this.$router.push({ name: 'tktzsendmsghistory', query: { wxMsgMasterId: this.$route.query.wxMsgMasterId } })
    }
  },
  mounted() {
    this.getJiaoXueTermSetup()
    if (this.$route.query.wxMsgMasterId != null) {
      this.getWxMsgMasterById()
    }
  }
}
</script>
<style scoped>

</style>
